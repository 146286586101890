import React from "react";

export type LocationProps = {
  state: {
    from: Location;
  };
  pathname: string;
};
export const baseWebApiUrl: string | undefined =
  process.env.REACT_APP_BASE_WEBAPPI_URL;
export interface ResetPasswordCredentials {
  changePasswordId: string;
  newPassword: string;
  recaptChaScore: number;
}
export interface ForgotPasswordCredentials {
  username: string;
  recaptChaScore: number;
}
export type recaptchaReturnData = {
  success: boolean;
  challenge_ts: string;
  hostname: string;
  score: number;
  action: string;
};
