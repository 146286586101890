import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import BottomBar from "./components/BottomBar";

import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<BottomBar />}>
        <Route index element={<ForgotPassword />} />
        <Route index path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
      </Route>
    </Routes>
  );
}
