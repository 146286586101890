import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Stack,
} from "@mui/material";
// import useLogin from "../hooks/useLogin";
import CheckIcon from "@mui/icons-material/Check";
import {
  ForgotPasswordCredentials,
  LocationProps,
  recaptchaReturnData,
} from "../services/api.interfaces";
import {
  LinktItem,
  MingleInput,
  MingleLabel,
  SignInUpContainer,
} from "../components/SignInUpContainer";
import * as yup from "yup";
import { useFormik } from "formik";
import { appInsights } from "../services/appInsights";
import { postForgotPassword } from "../services/api.calls";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect } from "react";

const ForgotPassword: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const theme = useTheme();
  const { t } = useTranslation();
  let location = useLocation() as unknown as LocationProps;
  let navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSend, setIsSend] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState(false);
  const validationSchema = yup.object({
    username: yup
      .string()
      .email(t("forgotPage.errorEmail") || "")
      .required(t("forgotPage.errorEmailRequired") || ""),
  });
  const [recaptChaScore, setRecaptChaScore] = React.useState<number>(1);
  const MingleCaptchaComponent = () => {
    // const { executeRecaptcha } = useGoogleReCaptcha();
    //
    // // Create an event handler so you can call the verification on button click event or form submit
    // const handleReCaptchaVerify = useCallback(async (): Promise<any> => {
    //   if (!executeRecaptcha) {
    //     return;
    //   }
    //   const token = await executeRecaptcha("submit");
    //
    //   const getReCaptchaResponse: recaptchaReturnData = await fetch(
    //     "https://corsproxy.minglesports.workers.dev/https://homebase.mingle.sport/action/verifyReCaptcha",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ token }),
    //     }
    //   ).then((res) => res.json());
    //   setRecaptChaScore(getReCaptchaResponse.score);
    //   return getReCaptchaResponse as recaptchaReturnData;
    // }, [executeRecaptcha]);
    //
    // // You can use useEffect to trigger the verification as soon as the component being loaded
    // useEffect(() => {
    //   handleReCaptchaVerify();
    // }, [handleReCaptchaVerify]);

    return (
      <>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          // disabled={recaptChaScore < 0.5}
          sx={{
            pointerEvents: isLoading ? "none" : "inherit",
          }}
          endIcon={
            isLoading && (
              <IconButton aria-label="" edge="end">
                <CircularProgress
                  size={15}
                  sx={{ color: "#68F3CB" }}
                  color="success"
                />
              </IconButton>
            )
          }
        >
          {t("forgotPage.sendLink")}
        </Button>
      </>
    );
  };

  const formik = useFormik({
    initialValues: { username: "", recaptChaScore: recaptChaScore },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: ForgotPasswordCredentials) => {
      setIsLoading(true);
      postForgotPassword({
        username: values.username,
        recaptChaScore: values.recaptChaScore,
      }).then(() => {
        setIsSend(true);
        setIsLoading(false);
      });
    },
  });

  React.useEffect(() => {
    appInsights.trackPageView({ name: "forgotPassword", pageType: "screen" });
    appInsights.trackEvent({ name: "forgotPassword" }, { type: "screen" });
  }, []);
  return (
    <SignInUpContainer
      showError={showError}
      errorMessage={t("forgotPage.sendError") || ""}
    >
      {isSend ? (
        <Stack spacing={3} sx={{ maxWidth: "400px" }}>
          <Avatar
            sx={{
              background: "#60EAC9",
              margin: "auto",
              color: "#0B1ECF",
              width: 60,
              height: 60,
            }}
          >
            <CheckIcon sx={{ fontSize: "35px" }} />
          </Avatar>
          <Typography
            variant="h6"
            component="h2"
            align="center"
            sx={{ color: "#000" }}
          >
            <strong>{t("forgotPage.success.title")}</strong>
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            align="center"
            sx={{ color: "#000" }}
            gutterBottom
          >
            {t("forgotPage.success.subtitle")}
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            sx={{ color: "#000", borderColor: "#000" }}
            onClick={() => {
              setIsSend(false);
              setIsLoading(false);
            }}
          >
            {t("forgotPage.success.sendAgain")}
          </Button>
          <Typography
            variant="body2"
            component="span"
            align="center"
            sx={{ color: "#000", marginTop: 0 }}
            gutterBottom
          >
            {t("forgotPage.success.clickToSendAgain")}
          </Typography>
        </Stack>
      ) : (
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          <Stack spacing={3}>
            <Typography variant="h6" component="h2" sx={{ color: "#000" }}>
              <strong>{t("forgotPage.title")}</strong>
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              sx={{ color: "#000" }}
              gutterBottom
            >
              {t("forgotPage.description")}
            </Typography>
            <FormControl variant="outlined">
              <MingleLabel shrink htmlFor="email">
                E-mail
              </MingleLabel>
              <MingleInput
                placeholder={t("forgotPage.placeHolderInput") || ""}
                type="email"
                name="username"
                id="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
            </FormControl>
            <MingleInput
              type="hidden"
              name="recaptChaScore"
              id="recaptChaScore"
              value={recaptChaScore}
            />
            <MingleCaptchaComponent />
            <Typography
              variant="body2"
              component="p"
              sx={{ color: "#000", textAlign: "center" }}
            >
              {t("forgotPage.protectedByReCaptcha")}{" "}
              <a href="https://policies.google.com/privacy">
                {t("forgotPage.protectedByReCaptchaPrivacy")}
              </a>{" "}
              &{" "}
              <a href="https://policies.google.com/terms">
                {t("forgotPage.protectedByReCaptchaPrivacyTerms")}
              </a>{" "}
              {t("forgotPage.protectedByReCaptchaPrivacyTermsApply")}.
            </Typography>
          </Stack>
        </form>
      )}
    </SignInUpContainer>
  );
};
export default ForgotPassword;
