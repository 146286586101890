// import useLogin from "../hooks/useLogin";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import {
  LocationProps,
  recaptchaReturnData,
  ResetPasswordCredentials,
} from "../services/api.interfaces";
import {
  MingleInput,
  MingleLabel,
  MingleTextFieldAdornMent,
  SignInUpContainer,
} from "../components/SignInUpContainer";
import * as yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import { appInsights } from "../services/appInsights";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { postResetPassword } from "../services/api.calls";
import { useTranslation } from "react-i18next";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect } from "react";

const ResetPassword: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();

  let location = useLocation() as unknown as LocationProps;
  let navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [isSend, setIsSend] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState(false);
  const [recaptChaScore, setRecaptChaScore] = React.useState<number>(1);
  const validationSchema = yup.object({
    changePasswordId: yup.string().required(t("resetPage.errorId") || ""),
    newPassword: yup
      .string()
      .required(t("resetPage.errorPasswordRequired") || ""),
  });
  const formik = useFormik({
    initialValues: {
      changePasswordId: searchParams.get("changePasswordId") || "",
      newPassword: "",
      recaptChaScore,
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values: ResetPasswordCredentials) => {
      setIsLoading(true);
      postResetPassword({
        changePasswordId: values.changePasswordId,
        newPassword: values.newPassword,
        recaptChaScore: values.recaptChaScore,
      })
        .then(() => {
          setIsLoading(false);
          setIsSend(true);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsSend(false);
          setShowError(true);
        });
    },
  });

  const MingleCaptchaComponent = () => {
    // const { executeRecaptcha } = useGoogleReCaptcha();
    //
    // // Create an event handler so you can call the verification on button click event or form submit
    // const handleReCaptchaVerify = useCallback(async (): Promise<any> => {
    //   if (!executeRecaptcha) {
    //     return;
    //   }
    //   const token = await executeRecaptcha("submit");
    //
    //   const getReCaptchaResponse: recaptchaReturnData = await fetch(
    //     "https://corsproxy.minglesports.workers.dev/https://homebase.mingle.sport/action/verifyReCaptcha",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({ token }),
    //     }
    //   ).then((res) => res.json());
    //   setRecaptChaScore(getReCaptchaResponse.score);
    //   return getReCaptchaResponse as recaptchaReturnData;
    // }, [executeRecaptcha]);
    //
    // // You can use useEffect to trigger the verification as soon as the component being loaded
    // useEffect(() => {
    //   handleReCaptchaVerify();
    // }, [handleReCaptchaVerify]);

    return (
      <>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          // disabled={recaptChaScore < 0.5}
          endIcon={
            isLoading && (
              <IconButton aria-label="" edge="end">
                <CircularProgress
                  size={15}
                  sx={{ color: "#68F3CB" }}
                  color="success"
                />
              </IconButton>
            )
          }
        >
          {t("resetPage.resetButton")}
        </Button>
      </>
    );
  };
  React.useEffect(() => {
    appInsights.trackPageView({ name: "resetPassword", pageType: "screen" });
    appInsights.trackEvent({ name: "resetPassword" }, { type: "screen" });
  }, []);
  return (
    <SignInUpContainer
      showError={showError}
      errorMessage={t("resetPage.sendError") || ""}
    >
      {!isSend ? (
        <form
          onSubmit={formik.handleSubmit}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          <Stack spacing={3}>
            <Typography
              variant="h6"
              component="h2"
              sx={{ color: "#000" }}
              gutterBottom
            >
              <strong>{t("resetPage.title")}</strong>
            </Typography>

            <FormControl variant="outlined">
              <MingleInput
                placeholder="Type here"
                type="hidden"
                name="changePasswordId"
                id="changePasswordId"
                value={formik.values.changePasswordId}
                onChange={formik.handleChange}
                error={
                  formik.touched.changePasswordId &&
                  Boolean(formik.errors.changePasswordId)
                }
                helperText={
                  formik.touched.changePasswordId &&
                  formik.errors.changePasswordId
                }
              />
            </FormControl>

            <FormControl variant="outlined">
              <MingleLabel shrink htmlFor="password">
                {t("resetPage.titlePasswordInput")}
              </MingleLabel>
              <MingleTextFieldAdornMent
                placeholder={t("resetPage.passwordInputPlaceholder") || ""}
                name="newPassword"
                id="newPassword"
                type={showPassword ? "text" : "password"}
                defaultValue=""
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibilty"
                        title="toggle password visibilty"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <MingleInput
              type="hidden"
              name="recaptChaScore"
              id="recaptChaScore"
              value={recaptChaScore}
            />
            <MingleCaptchaComponent />
            <Typography
              variant="body2"
              component="p"
              sx={{ color: "#000", textAlign: "center" }}
            >
              {t("resetPage.protectedByReCaptcha")}{" "}
              <a href="https://policies.google.com/privacy">
                {t("resetPage.protectedByReCaptchaPrivacy")}
              </a>{" "}
              &{" "}
              <a href="https://policies.google.com/terms">
                {t("resetPage.protectedByReCaptchaPrivacyTerms")}
              </a>{" "}
              {t("resetPage.protectedByReCaptchaPrivacyTermsApply")}.
            </Typography>
          </Stack>
        </form>
      ) : (
        <>
          <Typography
            variant="h3"
            component="h2"
            sx={{ color: "#000", fontWeight: "bold" }}
            gutterBottom
            textAlign="center"
          >
            {t("resetPage.success.title")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#000" }}
            gutterBottom
            textAlign="center"
          >
            {t("resetPage.success.subtitle")}
          </Typography>

          <CheckIcon
            sx={{
              fontSize: "56px",
              color: "#0B1ECF",
              border: "1px solid #0B1ECF",
              borderRadius: "50%",
              padding: "10px",
            }}
          />
          {/*<Button*/}
          {/*  component="a"*/}
          {/*  href="https://app.mingle.sport"*/}
          {/*  variant="contained"*/}
          {/*  color="secondary"*/}
          {/*>*/}
          {/*  Back to the app*/}
          {/*</Button>*/}
          {isMobile && isIOS && (
            <Button
              variant="contained"
              color="secondary"
              component="a"
              target="_blank"
              href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
            >
              {t("resetPage.backToApp")}
            </Button>
          )}
          {isMobile && isAndroid && (
            <Button
              variant="contained"
              color="secondary"
              target="_blank"
              component="a"
              href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
            >
              {t("resetPage.backToApp")}
            </Button>
          )}
        </>
      )}
    </SignInUpContainer>
  );
};
export default ResetPassword;
