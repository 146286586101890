import {
  baseWebApiUrl,
  ForgotPasswordCredentials,
  ResetPasswordCredentials,
} from "./api.interfaces";
import axios from "axios";

export const postResetPassword = (
  params: ResetPasswordCredentials
): Promise<any> => {
  // if (params.recaptChaScore < 0.5) return Promise.reject("Invalid recaptcha");
  return axios({
    method: "POST",
    url: `${baseWebApiUrl}/User/ResetPassword`,
    data: {
      changePasswordId: params.changePasswordId,
      newPassword: params.newPassword,
    },
  }).then<any>((res) => res.data);
};
export const postForgotPassword = (
  params: ForgotPasswordCredentials
): Promise<any> => {
  // if (params.recaptChaScore < 0.5) return Promise.reject("Invalid recaptcha");
  return axios({
    method: "POST",
    url: `${baseWebApiUrl}/User/ForgotPassword`,
    data: {
      username: params.username,
    },
  }).then<any>((res) => res.data);
};
